import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CardLabel } from '../CardLabel';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { convertStringToTitleCase, PASSENGERTYPE } from '../../../utils/constant';


export default function TravelerDetails(props: any) {
    const { t } = useTranslation();
    return (
        <Box className="conftion__wrapper">
            <Box className="conftion__header">

                <Typography className='conftionTitle'>{t('Traveller Details')}</Typography>

            </Box>
            <hr className='conftion_hr' />

            {props?.passenger?.map((item:any,index:number) => {
                const email = item.contact?.emails?.[0]?.email;
                const phone = item.contact?.phones?.[0]?.number;
                return (
                    <Box className="conftion_card">
                        <Box className="conftion_cardHeader">
                            <Typography className='conftion_cardTitle'>{`${item?.paxType ===PASSENGERTYPE.INFANT ? "Infant" : item?.paxType } ${item?.index}`}</Typography>
                        </Box>
                        <Box className="conftion_cardBody">
                            <Box>
                                <Typography className='confition_bodyTitle' component={'span'}>{t('Full Name')}:</Typography>
                                <Typography className='confition_bodysubTitle' component={'span'}> {convertStringToTitleCase(item?.firstName)} {convertStringToTitleCase(item?.lastName)}</Typography>
                            </Box>
                            {email ? <Box>
                                <Typography className='confition_bodyTitle' component={'span'}>{t('Email address')}:</Typography>
                                <Typography className='confition_bodysubTitle' component={'span'}>{email.toLowerCase()}</Typography>
                            </Box> : null}
                            {phone ? <Box>
                                <Typography className='confition_bodyTitle' component={'span'}>{t('Phone Number')}:</Typography>
                                <Typography className='confition_bodysubTitle' component={'span'}>{phone}</Typography>
                            </Box> : null}
                            <Box>
                                <Typography className='confition_bodyTitle' component={'span'}>{t('Date Of Birth')}:</Typography>
                                <Typography className='confition_bodysubTitle' component={'span'}>{moment(item?.dateOfBirth).format('DD MMM YYYY')}</Typography>
                            </Box>
                            {/* <Box>
                                <Typography className='confition_bodyTitle' component={'span'}> Frequent Flyer Program:</Typography>
                                <Typography className='confition_bodysubTitle' component={'span'}>Air Canada Aeroplan-333000999</Typography>
                            </Box> */}
                        </Box>
                    </Box>

                )
            })}


        </Box>
    )
}