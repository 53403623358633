import React, { useState } from 'react';
import { Paper, Typography, Grid, Link, Box, Tabs, Tab, Divider, Accordion, AccordionSummary, AccordionDetails, Popover,Button, ListItemText, List, ListItem, ListItemAvatar, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// @ts-ignore 
import Icon_flight from '../../../assets/icons/Icon_flight.svg';
// @ts-ignore 
import IconTravelPath from '../../../assets/icons/icon-travel-path.svg';
import moment from 'moment';
import { dateChangeAndDaysCount, formatDate, formatTime } from '../../../utils/util';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import PaidIcon from '@mui/icons-material/Paid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DollarIcon from '@mui/icons-material/MonetizationOn';
import { convertStringToTitleCase, getProcessesBrandedFare, groupBySectionTitle } from '../../../utils/constant';

interface Segment {
  id: string;
  flights: Flight[];
  cabin: Cabin[];
  brand: Brand[];
  layovers?: any[];
}

interface Flight {
  id: string;
  number: string;
  operatingFlightNumber: string;
  carrier: string;
  carrierName: string;
  operatingCarrier: string;
  operatingCarrierName: string;
  departureAirport: string;
  departureAirportCity: string;
  arrivalAirport: string;
  arrivalAirportCity: string;
  arrivalDateTime: string;
  departureDateTime: string;
  aircraftType: string;
  fareBasisCode: string;
}

interface Cabin {
  type: string;
  flightIds: string[];
}

interface Brand {
  code: string;
  brandName: string;
  flightIds: string[];
}

interface FlightInfoProps {
  airlineLogo: string;
  operateLogo: string;
  airline: string;
  changeFlightLink?: string;
  from: string;
  city: string;
  to: string;
  toCity: string;
  departure: string;
  duration: string;
  arrival: string;
  cabin: string;
  fareType: string;
  operatedBy: string;
  operatingCarrier?: string;
  multipleFlightsLink?: string;
  flightNumber?: string;
  flights?: Flight[];
  layover?: string;
  carrier?: string;
  totalLayovers?: number;
  index?: number;
  navigateToSearch?: Function;
  brandFeatures: any;
  freeBaggages: any;
  chargedBaggages: any;
  oneCabinBag: any;
  twoCabinBag: any;
}

interface Feature {
  application: keyof typeof applicationStatus;
  commercialName?: string;
}

const applicationStatus = {
  DisplayedButNotOffered: "Not offered",
  NotOffered: "Not offered",
  ServiceIsFree: "Free",
  Chargeable: "Chargeable"
};

const formatDateTime = (dateTime: string) => {
  return moment(dateTime).format('hh:mm A MMMM, DD');
};

const getSymbol = (feature: Feature) => {
  if (feature.application) {
    switch (feature.application) {

      case 'DisplayedButNotOffered':
      case 'NotOffered':
        return (<CloseIcon className='Fligth_FareIcon' />)

      case "ServiceIsFree":
        return (<CheckIcon className='Fligth_FareIcon' />)
      case "Chargeable":
        return (<DollarIcon className='Fligth_FareIcon' />)

      default:
        return null;
    }
  }
  return null;
};

const RenderFeature = ({ feature, name = "" }: any) => {
  if (feature) {
    return (
      <Box className="Fligth_FareInfo">
        <Box>{getSymbol(feature)}</Box>
        <Typography className="Fligth_FareContent">
          {name || convertStringToTitleCase(feature.commercialName)}
        </Typography>
      </Box>
    );
  }
  return null;
};

const FlightDetailsPopoverComp = ({ cabin, FareTypePopover, anchorElFareType, handleFareTypeClose, t, brandFeatures, freeBaggages, chargedBaggages, oneCabinBag, twoCabinBag }: any) => {
  let freeBaggageData = freeBaggages?.[0]?.quantity
  let freeBaggageDataWeight = chargedBaggages?.[0]?.weight;
  const groupedFeatures = groupBySectionTitle(brandFeatures);
const  tooltipContent= (
  <Box className='Fligth_FarePopover'>
  <Typography className='Fligth_FareTitle'>Basic | {t('Cabin')}: {cabin}</Typography>
  {(oneCabinBag || twoCabinBag || freeBaggages?.length || chargedBaggages?.length) ?
    <>
      <Typography className='Fligth_FareTitle'>{t('Bags')}</Typography>
      {(freeBaggageData !== undefined || freeBaggageDataWeight !== undefined) &&
        <>
          <Box className="Fligth_FareInfo">
            {<CheckIcon className='Fligth_FareIcon' />}
            <Typography className="Fligth_FareContent">
            {t('Checked Bags Included') + " "}  
            </Typography>
            {freeBaggageData === 0 && freeBaggageDataWeight === 0 ?
              <Typography className="Fligth_FareContent_Amount">
                {freeBaggageData}
              </Typography>
              :
              <Typography className="Fligth_FareContent_Amount">
                {freeBaggageData
                  ? freeBaggageData
                  : freeBaggageDataWeight + " Kg"}
              </Typography>
            }
          </Box>
        </>
      }
      {chargedBaggages &&
        chargedBaggages.map(
          (bagData: any) => {
            const { firstPiece, lastPiece, amount } = bagData ?? { firstPiece: "", lastPiece: "", amount: "" };
            let chargeBaggageValue;
            if (lastPiece) {
              if (firstPiece === lastPiece)
                chargeBaggageValue = ("Fee For Additional Bag ") + lastPiece;

              if (firstPiece !== lastPiece)
                chargeBaggageValue = ("Fee For Additional Bag ") + firstPiece + "-" + lastPiece;

            }
            if (!lastPiece) {
              chargeBaggageValue = ("Fee For Additional Bag ") + firstPiece;
            }

            if (firstPiece || lastPiece)
              return (
                <>
                  <Box className="Fligth_FareInfo">
                    {<DollarIcon className='Fligth_FareIcon' />}
                    <Typography className="Fligth_FareContent">
                      {(chargeBaggageValue)}
                    </Typography>
                    <Typography className="Fligth_FareContent_Amount">
                      $ {amount}
                    </Typography>
                  </Box>
                </>
              );
          }
        )
      }
      {oneCabinBag && <RenderFeature feature={oneCabinBag} />}
      {twoCabinBag && <RenderFeature feature={twoCabinBag} />}
    </>
    :
    null
  }
  {groupedFeatures && Object.keys(groupedFeatures).map((sectionTitle: any) => (
    <>
      <Typography className='Fligth_FareTitle'>{sectionTitle}</Typography>
      {groupedFeatures[sectionTitle]
        .map((feature: any) => (
          <Box className="Fligth_FareInfo" key={feature.commercialName}>
            <Box>{getSymbol(feature)}</Box>
            <Typography className="Fligth_FareContent">
              {convertStringToTitleCase(feature.commercialName)}
            </Typography>
          </Box>
        ))}
    </>
  ))}
</Box>
);
  return (<>
    <Tooltip title={tooltipContent}
                arrow
                enterDelay={100} 
                leaveDelay={200}
                sx={{
                    '.MuiTooltip-tooltip': {
                        borderRadius: '5px', // Optional: rounded corners
                        boxShadow: '0px 4px 18px rgba(97, 97, 97, 0.3)', // Optional: shadow for the tooltip
                        padding:'0',
                        overflow:'auto',
                    }
                }}
                PopperProps={{
                 className:"FlightDetails_tooltips",
                }}>
     <InfoIcon className='filghtFare_typePopover'/>
      </Tooltip>
    {/* <Popover
      id="mouse-over-popoverpop"
      sx={{ pointerEvents: 'none' }}
      open={FareTypePopover}
      anchorEl={anchorElFareType}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleFareTypeClose}
      disableRestoreFocus
    > */}
      
    {/* </Popover> */}
  </>)
}

const FlightInfo: React.FC<FlightInfoProps> = ({
  airlineLogo,
  operateLogo,
  airline,
  changeFlightLink,
  from,
  city,
  to,
  toCity,
  departure,
  duration,
  arrival,
  cabin,
  fareType,
  operatedBy,
  carrier,
  multipleFlightsLink,
  flightNumber,
  brandFeatures,
  freeBaggages,
  chargedBaggages,
  oneCabinBag,
  twoCabinBag
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [anchorElFareType, setAnchorElFareType] = useState<null | HTMLElement | SVGSVGElement>(null);
  const open = Boolean(anchorEl);
  const FareTypePopover = Boolean(anchorElFareType);

  const handleFareTypePopover = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorElFareType(event.currentTarget);
  };

  const handleFareTypeClose = () => {
    setAnchorElFareType(null);
  };

  return (
    <>
      <Box className="booking_flightInfo">
        <Box className="booking_InfoHeader">
          <Box className="booking_header">
            <img src={`${process.env.REACT_APP_FLIGHT_LOGO_URL}/${airlineLogo}`} className='infoHeader_Icon' alt={`${airline} logo`} />
            <Typography variant="subtitle1" className='infoHeader_title'>
              {airline}
            </Typography>
          </Box>
          {changeFlightLink && <Link className='flight_ChangeFlightBtn' href={changeFlightLink}>{t('Change Flight')}</Link>}
        </Box>
        <Box className="flightInfo_Content">
          <Box className="flightInfo_frombox">
            <Typography variant="body2" className='flightInfo_from'>{from}</Typography>
            <Typography variant="body2" className='flightInfo_City'>{city}</Typography>
          </Box>
          <Box className="flightInfo_TravelPath">
            <img src={IconTravelPath} className='IconTravelPath' />
            <Box className="flightInfo_PathInfo">
              <Box className='flightInfo_Time_date'>
                <Typography variant="body2" className='flightInfo_departure flightInfo_departure_time'>{formatTime(departure)} </Typography>
                <Typography variant="body2" className='flightInfo_duration' ml={"5px"}> {formatDate(departure)}</Typography>
              </Box>
              <Box className="flightInfo_frombox flightInfo_ToBox">
                <Typography variant="body2" className='flightInfo_from'>{to}</Typography>
                <Typography variant="body2" className='flightInfo_City'>{toCity}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className='flightInfo_Time_date'>
            <Typography variant="body2" className='flightInfo_arrival flightInfo_departure_time'> {formatTime(arrival)} </Typography>
            <Typography variant="body2" className='flightInfo_arrival' > {formatDate(arrival)}</Typography>
          </Box>
          <Box className="flightInfo_cabinInfo">
            <Box className="flightInfo_cabinContent">       
             <Typography variant="body2" className='flightInfo_title'>
              {t('Cabin')}:
            </Typography>
            <Typography variant="body2" className='flightInfo_SubTitle'>
              {cabin}
            </Typography>
            <Typography variant="body2" className='flightInfo_title'>
                | {t('Fare type')}:
            </Typography>
            <Typography variant="body2" className='flightInfo_SubTitle flightInfo_fareType'>
              {fareType}
            </Typography>
            </Box>
            {/* <InfoIcon className='filghtFare_typePopover' aria-owns={FareTypePopover ? 'mouse-over-popoverpop' : undefined} aria-haspopup="true" onMouseEnter={handleFareTypePopover} onMouseLeave={handleFareTypeClose} /> */}
            <FlightDetailsPopoverComp
              cabin={cabin}
              FareTypePopover={FareTypePopover}
              anchorElFareType={anchorElFareType}
              handleFareTypeClose={handleFareTypeClose}
              t={t}
              brandFeatures={brandFeatures}
              freeBaggages={freeBaggages}
              chargedBaggages={chargedBaggages}
              oneCabinBag={oneCabinBag}
              twoCabinBag={twoCabinBag}
            />
          </Box>

          <Box className="flightInfo_Operated">
            <Typography variant="body2" className='flightInfo_OperTtile'>
            <img src={`${process.env.REACT_APP_FLIGHT_LOGO_URL}/${operateLogo}`} width={15} className='infoHeader_Icon' alt={`${operatedBy} logo`} /> {t('Operated by')} {operatedBy}  &nbsp; | &nbsp;
            </Typography>
            <Typography variant="body2" className='flightInfo_OperSubTtile'>
              {multipleFlightsLink && <Link href={multipleFlightsLink}>{t('Multiple flights')}</Link>} {flightNumber && `  ${carrier} ${flightNumber}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const FlightSegment: React.FC<FlightInfoProps> = ({
  airlineLogo,
  operateLogo,
  airline,
  changeFlightLink,
  from,
  city,
  to,
  toCity,
  departure,
  duration,
  arrival,
  cabin,
  fareType,
  operatedBy,
  operatingCarrier,
  multipleFlightsLink,
  flightNumber,
  flights,
  layover,
  carrier,
  totalLayovers,
  navigateToSearch,
  index,
  brandFeatures,
  freeBaggages,
  chargedBaggages,
  oneCabinBag,
  twoCabinBag
}) => {
  const { t } = useTranslation();
  
  const [anchorElNew, setAnchorElNew] = React.useState<HTMLElement | null>(null);
  const [anchorElFareType, setAnchorElFareType] = useState<null | HTMLElement | SVGSVGElement>(null);

 
  const FareTypePopover = Boolean(anchorElFareType);


  const handleFareTypePopover = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorElFareType(event.currentTarget);
  };

  const handleFareTypeClose = () => {
    setAnchorElFareType(null);
  };


  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNew(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElNew(null);
  };
 
  const open = Boolean(anchorElNew);
  return (
    <>
      <Divider variant="middle" sx={{ border: '0' }} />
      <Box className="FlightDetl_footer">
        <Box className="FlightDetl_filgthIcon">
          <Box className="FlightDetl_fIconHeader">
            <img className="FlightDetl_filgthIcons" src={`${process.env.REACT_APP_FLIGHT_LOGO_URL}/${airlineLogo}`} alt={airline} />
            <Typography variant="body2" className="FlightDetl_filgthTitle">{airline}</Typography>
          </Box>
          {index === 0 ? <Typography onClick={() => navigateToSearch()} className='flight_ChangeFlightBtn'>{t('Change Flight')}</Typography> : null}
        </Box>
      </Box>
      <Box className="booking_flightInfo" pt={"0 !important"}>
        <Box className="flightInfo_Content">
          <Box className="flightInfo_frombox" mt={"0 !important"}>
            <Typography variant="body2" className='flightInfo_from'>{from}</Typography>
            <Typography variant="body2" className='flightInfo_City'>{city}</Typography>
          </Box>
          <Box className="flightInfo_TravelPath">
            <img src={IconTravelPath} className='IconTravelPath' />
            <Box className="flightInfo_PathInfo">
              <Box className='flightInfo_Time_date'>
                <Typography variant="body2" className='flightInfo_departure flightInfo_departure_time'>{formatTime(departure)} </Typography>
                <Typography variant="body2" className='flightInfo_departure'> {formatDate(departure)}</Typography>
              </Box>
              <Box display={'flex'}>
                <Typography variant="body2" className='flightInfo_duration'> {duration} |</Typography>
                <Typography variant="body2" className='FlightDetl_layover' ml={'5px'}>{layover}</Typography>
              </Box>
              <Box className="flightInfo_frombox flightInfo_ToBox">
                <Typography variant="body2" className='flightInfo_from'>{to}</Typography>
                <Typography variant="body2" className='flightInfo_City'>{toCity}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className='flightInfo_Time_date'>
            <Typography variant="body2" className='flightInfo_arrival flightInfo_departure_time'> {formatTime(arrival)} </Typography>
            <Typography variant='body2' className='flightInfo_arrival'>{formatDate(arrival)}{
              (() => {
                const daysDifference = dateChangeAndDaysCount(departure, arrival);
                return daysDifference > 0 ? <sup className='flightInfo_AddOneFlight'>+{daysDifference}</sup> : null;
              })()
            }</Typography>
          </Box>

          <Box className="flightInfo_cabinInfo">
          <Box className="flightInfo_cabinContent">       
            <Typography variant="body2" className='flightInfo_title'>
              {t('Cabin')}:
            </Typography>
            <Typography variant="body2" className='flightInfo_SubTitle'>
              {t(cabin)}
            </Typography>
            <Typography variant="body2" className='flightInfo_title flightInfo_titleFaretype'>
                | {t('Fare type')}:
            </Typography>
            <Typography variant="body2" className='flightInfo_SubTitle flightInfo_fareType'>
              {fareType}
            </Typography>
            </Box>
            {/* <Button aria-describedby={id} variant="contained" onClick={handleFareTypePopover}> */}
            {/* <InfoIcon className='filghtFare_typePopover' aria-owns={FareTypePopover ? 'mouse-over-popoverpop' : undefined} aria-haspopup="true" onMouseEnter={handleFareTypePopover}  onMouseLeave={handleFareTypeClose}/> */}
            {/* </Button> */}
      
        
            <FlightDetailsPopoverComp
              cabin={cabin}
              FareTypePopover={FareTypePopover}
              anchorElFareType={anchorElFareType}
              handleFareTypeClose={handleFareTypeClose}
              t={t}
              brandFeatures={brandFeatures}
              freeBaggages={freeBaggages}
              chargedBaggages={chargedBaggages}
              oneCabinBag={oneCabinBag}
              twoCabinBag={twoCabinBag}
            />
          </Box>

          <Box className="flightInfo_Operated">
            <Typography variant="body2" className='flightInfo_OperTtile'>
            <img src={`${process.env.REACT_APP_FLIGHT_LOGO_URL}/${operateLogo}`} width={15} className='infoHeader_Icon' alt={`${operatedBy} logo`} /> {t('Operated by')} {operatedBy}  &nbsp; | &nbsp;
            </Typography>
         
              {totalLayovers > 0 ? (
                <>
                      <Typography
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      className='Multipleflights_title'
                      >
                      {t('Multiple flights')}
                    </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{ pointerEvents: 'none' }}
              open={open}
              anchorEl={anchorElNew}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
                <Box className="MultipleFlightDet_popover">
                                <Typography  className ='MultipleFlightDet_title'variant='body1'>Multiple Flight Details</Typography>
                                {flights.map((flight, index) => (
                                  <Typography key={index} variant="body2"  className='MultipleFlightDet_subTitle'>
                                  <span  className='MultipleFlightDet_flights'>  {flight.departureAirport} - {flight.arrivalAirport} :</span> {flight.carrierName} | {flight.operatingCarrier}{flight.operatingFlightNumber}
                                  </Typography>
                                ))}
                  </Box>
             </Popover>
                         
              </>
            ) : (
              <Typography variant="body2" className="flightInfo_OperSubTtile">  {operatingCarrier} {flightNumber}   </Typography>
            )}
       
          </Box>
        </Box>
      </Box>
    </>
  );
};

const FlightDetails: React.FC<{ segments: Segment[], navigateToSearch: Function, fareDetailsData: any }> = ({ segments, navigateToSearch, fareDetailsData }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const brandFeatures = fareDetailsData?.brandFeatures ? fareDetailsData?.brandFeatures : [];
  const freeBaggages = fareDetailsData?.freeBaggages ? fareDetailsData?.freeBaggages : [];
  const chargedBaggages = fareDetailsData?.chargedBaggages ? fareDetailsData?.chargedBaggages : [];
  const { commercialNameMappings, subCodeMappings } = getProcessesBrandedFare(brandFeatures)
  let oneCabinBag = commercialNameMappings["1 CABIN BAG UP TO 8KG"];
  let twoCabinBag = commercialNameMappings["2 CABIN BAGS UP TO 8KG"];

  const mapFlightToFlightInfoProps = (flight: Flight, cabin: string, fareType: string, brandFeatures: any, freeBaggages: any, chargedBaggages: any, oneCabinBag: any, twoCabinBag: any) => {
    const duration = calculateDuration(flight.departureDateTime, flight.arrivalDateTime);
    return {
      airlineLogo: `${flight.carrier.toLowerCase()}.png`,
      operateLogo: `${flight.operatingCarrier.toLowerCase()}.png`,
      airline: flight.carrierName,
      from: flight.departureAirport,
      city: flight.departureAirportCity,
      to: flight.arrivalAirport,
      toCity: flight.arrivalAirportCity,
      departure: flight.departureDateTime,
      duration: duration,
      arrival: flight.arrivalDateTime,
      cabin: cabin,
      fareType: fareType,
      operatedBy: flight.operatingCarrierName,
      flightNumber: flight.number,
      carrier: flight.carrier,
      brandFeatures: brandFeatures,
      freeBaggages:freeBaggages,
      chargedBaggages:chargedBaggages,
      oneCabinBag:oneCabinBag,
      twoCabinBag:twoCabinBag
    };
  };

  const mapSegmentsToProps = (segment: Segment, brandFeatures: any, freeBaggages: any, chargedBaggages: any, oneCabinBag: any, twoCabinBag: any) => {
    console.log(segment)
    const layover = segment.layovers?.length ? `${segment.layovers?.length} Stops` : "Non-stop";
    const totalLayovers = segment.layovers?.length || 0;
    const firstFlight = segment.flights[0];
    const lastFlight = segment.flights[segment.flights.length - 1];
    const duration = calculateDuration(firstFlight.departureDateTime, lastFlight.arrivalDateTime);
    const cabin = segment.cabin.find(cabin => cabin.flightIds.includes(firstFlight.id))?.type;
    const fareType = segment.brand.find(brand => brand.flightIds.includes(firstFlight.id))?.brandName;
    const flights = segment.flights;

    return {
      airlineLogo: `${firstFlight.carrier.toLowerCase()}.png`,
      operateLogo: `${firstFlight.operatingCarrier.toLowerCase()}.png`,
      airline: firstFlight.carrierName,
      from: firstFlight.departureAirport,
      city: firstFlight.departureAirportCity,
      to: lastFlight.arrivalAirport,
      toCity: lastFlight.arrivalAirportCity,
      departure: firstFlight.departureDateTime,
      arrival: lastFlight.arrivalDateTime,
      duration: duration,
      operatedBy: firstFlight.operatingCarrierName,
      operatingCarrier: firstFlight.operatingCarrier,
      flightNumber: firstFlight.number,
      flights: flights,
      cabin: cabin,
      fareType: fareType,
      layover: layover,
      carrier: firstFlight.carrier,
      totalLayovers: totalLayovers,
      brandFeatures: brandFeatures,
      freeBaggages: freeBaggages,
      chargedBaggages: chargedBaggages,
      oneCabinBag: oneCabinBag,
      twoCabinBag: twoCabinBag
    }
  }

  const calculateDuration = (departure: string, arrival: string) => {
    const departureTime = new Date(departure);
    const arrivalTime = new Date(arrival);
    const durationInMinutes = (arrivalTime.getTime() - departureTime.getTime()) / (1000 * 60);

    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  return (
    <>
      {/* <Box className="Booking_FlightUpliftHeader">
      <Typography className='Booking_FlightUpliftTitle'>Pay monthly from <span className="monthly-price up-from-currency" data-up-from-currency="">$</span>
      <span className="monthly-price" data-up-from-currency-unit-major=""></span>
      mon with </Typography>
      <img alt="uplift logo" className='UpLiftImg'   src="https://s3-us-west-2.amazonaws.com/travel-img-assets/logos/uplift.svg"></img>
    </Box> */}
      <Box className='Booking_FlightDetails'>
        <Box className='Booking_flightHeader'>
          <img src={Icon_flight} className='Icon_flight' />
          <Typography className='Booking_flightTitle' variant='h5'>
            {t('Flight Details')}
          </Typography>
        </Box>
        <Box className="booking_FlightDetlsHeader">
          {segments.map((segment, index) => {
            const flightSegmentProps = mapSegmentsToProps(segment, brandFeatures, freeBaggages, chargedBaggages, oneCabinBag, twoCabinBag);
            return <FlightSegment key={index} index={index} navigateToSearch={navigateToSearch} {...flightSegmentProps} />;
          })}
        </Box>
        <Accordion className='booking_FullDetlAccordion'>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className='booking_filghtFullDetl'>{t('Full Flight Details')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="Booking_FlightContent">
              <Tabs value={selectedTab} onChange={handleChange}>
                {segments.map((segment, index) => {
                  const firstFlight = segment.flights[0];
                  const lastFlight = segment.flights[segment.flights.length - 1];
                  const tabLabel = `${firstFlight.departureAirport} > ${lastFlight.arrivalAirport}`;
                  return (
                    <Tab key={segment.id} label={tabLabel} />
                  );
                })}
              </Tabs>
              {segments.map((segment, index) => (
                <Box key={segment.id} hidden={selectedTab !== index}>
                  {segment.flights.map(flight => {
                    const cabinType = segment.cabin.find(cabin => cabin.flightIds.includes(flight.id))?.type;
                    const fareType = segment.brand.find(brand => brand.flightIds.includes(flight.id))?.brandName;
                    const flightInfoProps = mapFlightToFlightInfoProps(flight, cabinType, fareType, brandFeatures, freeBaggages, chargedBaggages, oneCabinBag, twoCabinBag);
                    return <FlightInfo key={flight.id} {...flightInfoProps} />;
                  })}
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>

  );
};

export default FlightDetails;
