import React, { useState } from 'react';
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import WcIcon from '@mui/icons-material/Wc';
import CoffeeIcon from '@mui/icons-material/Coffee';
import { useTranslation } from 'react-i18next';

interface SeatProps {
    seatData: any;
    letter: string;
    isAvailable: boolean;
    onClickSeat: (letter: string, row: number, travellerId: string, isSwapping: boolean) => void;
    travellerId: string;
    row: number;
    isSwapping: boolean;
    isSeatDisabled: boolean;
}

const Seat = ({ seatData, letter, isAvailable, row, onClickSeat, travellerId, isSwapping, isSeatDisabled }: SeatProps) => {
    const { t } = useTranslation();

    const getSeatButtonClass = () => {
        if (isSeatDisabled) return 'seats seat_Disabled';
        if (isAvailable && travellerId) {
            return 'seats seat_SelectActive';
        }
        if (isAvailable && (seatData.chargeable === "Paid" || seatData.chargeable === "PaidContactCarrier")) {
            return 'seats seat_Yellow';
        }
        return isAvailable ? 'seats seat_EmetyActive' : 'seats seat_Emety';
    };

    const handleSeatClick = () => {
        if (isSeatDisabled) { return }
        if (isAvailable && seatData.chargeable !== "Paid" && seatData.chargeable !== "PaidContactCarrier" && !seatData.characteristics.includes('NoSeat')) {
            onClickSeat(letter, row, travellerId, isSwapping);
        }
    };

    const buttonClass = getSeatButtonClass();

    // Create the content for the Tooltip
    const tooltipContent = (
        <Box className='SeatMapInfo_wrapper'>
            <Box sx={{display:'flex'}} className="SeatMapInfo_header">
            <Typography variant="body1">
                {`${t('Seat')}: ${row}${letter}`}
            </Typography>
            {seatData?.fare?.totalAmount && seatData?.chargeable === "Paid" && (
                <Typography variant="body1">
                    {`${seatData?.fare?.totalAmount?.currency} ${seatData?.fare?.totalAmount?.amount}`}
                </Typography>
            )}
            </Box>
            <Divider />
            <Box  className="seatMapInfo_details">
                {seatData?.limitations?.includes('NoSeatLavatory') ? (
                    <Box >
                        <SeatInfoList data={["Lavatory"]} />
                    </Box>
                ) : (
                    <Box sx={{ pt: 1 }}>
                        <SeatInfoList title={t("Locations")} data={seatData?.locations} />
                        <SeatInfoList title={t("Facilities")} data={seatData?.facilities} />
                        <SeatInfoList title={t("Characteristics")} data={seatData?.characteristics} />
                        <SeatInfoList title={t("Limitations")} data={seatData?.limitations} />
                    </Box>
                )}
            </Box>
        </Box>
    );

    return (
        <>
            <Tooltip
                title={tooltipContent}
                placement="top"
                arrow
                enterDelay={500} 
                leaveDelay={200}
                sx={{
                    '.MuiTooltip-tooltip': {
                        maxWidth: '300px', // Set the max width of the tooltip
                        minwidth: '300px',
                        width: '300px',  
                        borderRadius: '5px', // Optional: rounded corners
                        boxShadow: '0px 4px 18px rgba(97, 97, 97, 0.3)', // Optional: shadow for the tooltip
                        color: '#000000', // Text color inside the tooltip
                        fontSize: '14px', // Font size inside the tooltip
                        padding:'0'
                    }
                }}
                PopperProps={{
                 className:"SeatMap_tooltips",
                }}
            >
                <Button
                    className={buttonClass}
                    onClick={handleSeatClick}
                    style={{ position: 'relative' }}
                >
                    {seatData?.limitations?.includes('NoSeatLavatory') && (
                        <WcIcon
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '16px',
                                color: 'gray'
                            }}
                        />
                    )}
                    {seatData?.limitations?.includes('NoSeatGalley') && (
                        <CoffeeIcon
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '16px',
                                color: 'gray'
                            }}
                        />
                    )}
                </Button>
            </Tooltip>
        </>
    );
};

export default Seat;

interface SeatInfoProps {
    title?: string;
    data: string[];
}

const SeatInfoList = ({ title, data }: SeatInfoProps) => {
    if (!data || data.length === 0) return null;

    return (
        <Box className="seatMapInfoDetails">
            {title && <Typography variant="body2" className='seatMapInfo_title' >{title}:</Typography>}
            <ul className='seatMapInfo_list'>
                {data.map((item, index) => (item && item !== "" &&
                    <li key={index} className='seatMapInfo_point'>
                        <Typography variant="body2">{item}</Typography>
                    </li>
                ))}
            </ul>
        </Box>
    );
};
