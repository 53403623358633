import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';

function valuetext(value: number) {
  return `${value}°C`;
}

export default function FilterRange(props:any) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<number[]>([props?.sliderMinValue ? props?.sliderMinValue: 0, props?.sliderMaxValue ? props?.sliderMaxValue: Infinity]);
  
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    props.sliderFilterChange(event,newValue)
  };

  
  // Function to format timestamps into desired format
  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true }); // Format as h:mm a
  };
  
  const formatDuration = (duration: number): string => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours} ${t('hr')} ${minutes} ${t('min')}`;
  };

  const formatValues = (value:any)=>{
    if(props.name=="getDepartureTimeRange"||props.name=="getArrivalTimeRange"){
      let data=formatDate(value);
      return data;
    }else if(props.name=="getTotalDuration"||props.name=="getLayoverTime"){
      let data=formatDuration(value);
      return data;
    }else if(props.name=="findMinMaxValues"){
      let data=value.toFixed(2);
      return data;
    }else{
      return value
    }
  }

  React.useEffect(()=>{
    if(props?.resetValue){
      setValue([0,Infinity]);
      props.resetFlag(props?.name,false);
    }
  },[props?.resetValue,props?.name])
  

  
  return (
    <Box px={'20px'} mb={'1rem'}>
      <Slider
        size="small"
        sx={{ '& .MuiSlider-thumb': { backgroundColor: '#fff', border: '1px solid #1C75BC', width: '26px', height: '26px' }, '& .MuiSlider-track': { backgroundColor: '#1C75BC', height: '3px' }, '& .MuiSlider-rail': { backgroundColor: '#DDDDDD', height: '3px' }, '& .MuiSlider-valueLabel': { backgroundColor: "#ffffff", fontSize: '12px', color: '#1C75BC', padding: '0' } }}
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="on"
        getAriaValueText={valuetext}
        min={props?.sliderMinValue}
        max={props?.sliderMaxValue}
        step={props?.steps}
        name={props.name}
        valueLabelFormat={(value) => formatValues(value)}
        
      />
    </Box>
  );
}


