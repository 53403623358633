import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";

export interface brandedFareResultState {
    brandedFareResult: {};
    pricingData: {};
    selectedBrandedFareId: {};
    selectedItineraryFare: {}
}

const initialState: brandedFareResultState = {
    brandedFareResult: {},
    pricingData: {},
    selectedBrandedFareId: {},
    selectedItineraryFare: {}
}

const brandedFare = createSlice({
    name: "brandedFareResult",
    initialState,
    reducers: {
        [`${ACTIONS.BRANDED_FARE_RESULTS}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, brandedFareResult: action.payload}
        },
        [`${ACTIONS.PRICING_RESULTS}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, pricingData: action.payload}
        },
        [`${ACTIONS.SELECTED_BRANDED_FARE_ID}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, selectedBrandedFareId: action.payload}
        },
        [`${ACTIONS.SELECTED_BRANDED_ITINERARYFARE}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, selectedItineraryFare: action.payload}
        },
    }
});

export const { BRANDED_FARE_RESULTS, PRICING_RESULTS, SELECTED_BRANDED_FARE_ID, SELECTED_BRANDED_ITINERYFARE } = brandedFare.actions;
export default brandedFare.reducer;
