export const ACTIONS = {
    "UPDATE_SEARCH_RESULTS": "UPDATE_SEARCH_RESULTS",
    "SEARCH_IS_LOADING": "SEARCH_IS_LOADING",
    "TRIP_TYPE": "TRIP_TYPE",
    "SEARCHRESULTHIT": "SEARCHRESULTHIT",

    //brandedFares
    "BRANDED_FARE_RESULTS": "BRANDED_FARE_RESULTS",
    "SELECTED_BRANDED_FARE_ID": "SELECTED_BRANDED_FARE_ID",
    "PRICING_RESULTS": "PRICING_RESULTS",
    "SELECTED_BRANDED_ITINERARYFARE": "SELECTED_BRANDED_ITINERARYFARE",

    // Booking
    "BOOKING_RESULT": "BOOKING_RESULT",

    "SEARCH_REQUEST": "SEARCH_REQUEST",
    "UPLIFT_RESULT":"UPLIFT_RESULT",
    //Quote Booking
    "BOOKING_QUOTE": "BOOKING_QUOTE",
    "CORRELATIONID": "CORRELATIONID",
    "GIFTCARD_RESULT":"GIFTCARD_RESULT",

    //language Changes
    "LANGUAGE_CHANGED": "LANGUAGE_CHANGED",
    "PROVIDERCONFIG":"PROVIDERCONFIG",
    "BOOKING_AGENCY":"BOOKING_AGENCY",
    "BOOKING_AGENT":"BOOKING_AGENT"
}