import { AxiosRequestConfig, AxiosResponse } from "axios";
import { b2cService } from "../axiosServices";

interface RequestBodyInterface{
    fareId:String;
    token:String;
    correlationId:String;

}

const getProviderConfigService = async () => {
    
    // let data:any=await b2cService.post(`/getBrandedFares/v2/brandedFares?token=${token}`,fareId, requestConfig);
    let data = {
        softvoyage: {
            skipBrandedFares: true
        },
        default: {
            skipBrandedFares: false
        }
    }
    
    return data;
}

export { getProviderConfigService };