import React, { useEffect, useState, ChangeEvent } from 'react'
import { Button, Typography, Box, useMediaQuery } from '@mui/material';
import LuggageIcon from '@mui/icons-material/Luggage';
import FilterTopTitle from '../common/Filter/FilterTopTitle';
import FilterTitle from '../common/Filter/FilterTitle';
import FilterCheckBox from '../common/Filter/FilterCheckBox';
import FilterRange from '../common/Filter/FilterRange';
import TuneIcon from '@mui/icons-material/Tune';
import FilterRangeTilte from '../common/Filter/FilterRangeTilte';
// @ts-ignore
import { timeConvert } from '../../utils/commonFunctions';
import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useTranslation } from 'react-i18next';
import { parseAmount } from '../../utils/constant';

interface CarrierFilter{
    [key: string]: {carrier?: string;
        fare?: number;
        carrierName?: string;
        count?: number;
    }
}

interface LayoverInterface {
    [key: string]: {
        airport: string,
        airportCity: string
    }
}
const FilterSection = (props: any) => {
    const { t } = useTranslation();
     const matches = useMediaQuery('(min-width:600px)');
    const [filterToggleMb, setFilterToggleMb] = useState(matches ? true : false);
    let filter = props?.filterData;
    let TripInfoData = props?.TripInfoData;
    let [filterData, setFilterData] = useState(
        {
            findCarriers: [],
            noOfStops: [],
            getBaggageData: [],
            getDepartureAirport: [],
            getArrivalAirport: [],
            getLayovers: [],
            findMinMaxValues: { minimumValue: 0, maximumValue: 0 },
            getDepartureTimeRange: { earliestDepartureTime: "", latestDepartureTime: "", earliestDepartureDateTime: "", latestDepartureDateTime: "" },
            getArrivalTimeRange: { earliestArrivalTime: "", latestArrivalTime: "", earliestArrivalDateTime: "", latestArrivalDateTime: "" },
            getTotalDuration: { minTotalTime: "", maxTotalTime: "", minTotalDuration: 0, maxTotalDuration: 0 },
            getLayoverTime: { maxLayoverDuration: 0, maxLayoverTime: "", minLayoverDuration: 0, minLayoverTime: "" }
        });
    let [appliedFilter, setAppliedFilter] = useState(
        {
            findCarriers: [],
            noOfStops: [],
            getBaggageData: [],
            getDepartureAirport: [],
            getArrivalAirport: [],
            getLayovers: [],
            findMinMaxValues: { minimumValue: 0, maximumValue: 0 },
            getDepartureTimeRange: { earliestDepartureTime: "", latestDepartureTime: "", earliestDepartureDateTime: "", latestDepartureDateTime: "" },
            getArrivalTimeRange: { earliestArrivalTime: "", latestArrivalTime: "", earliestArrivalDateTime: "", latestArrivalDateTime: "" },
            getTotalDuration: { minTotalTime: "", maxTotalTime: "", minTotalDuration: 0, maxTotalDuration: 0 },
            getLayoverTime: { maxLayoverDuration: 0, maxLayoverTime: "", minLayoverDuration: 0, minLayoverTime: "" }
        })

    let [priceRangeReset, setPriceRangeReset] = useState(false);
    let [dateReset, setDateReset] = useState(false);
    let [timeReset, setTimeReset] = useState(false);
    let [allChecked, setAllChecked] = useState(false);
    let [fromCity, setFromCity] = useState('');
    let [toCity, setToCity] = useState('');

    const defaultFilter: {
        findCarriers: string[];
        noOfStops: any[];
        getBaggageData: any[];
        getDepartureAirport: any[];
        getArrivalAirport: any[];
        getLayovers: any[];
        findMinMaxValues: { minimumValue: number; maximumValue: number };
        getDepartureTimeRange: {
            earliestDepartureTime: string;
            latestDepartureTime: string;
            earliestDepartureDateTime: string;
            latestDepartureDateTime: string;
        };
        getArrivalTimeRange: {
            earliestArrivalTime: string;
            latestArrivalTime: string;
            earliestArrivalDateTime: string;
            latestArrivalDateTime: string;
        };
        getTotalDuration: {
            minTotalTime: string;
            maxTotalTime: string;
            minTotalDuration: number;
            maxTotalDuration: number;
        };
        getLayoverTime: {
            maxLayoverDuration: number;
            maxLayoverTime: string;
            minLayoverDuration: number;
            minLayoverTime: string;
        };
    } = {
        findCarriers: [],
        noOfStops: [],
        getBaggageData: [],
        getDepartureAirport: [],
        getArrivalAirport: [],
        getLayovers: [],
        findMinMaxValues: { minimumValue: 0, maximumValue: 0 },
        getDepartureTimeRange: {
            earliestDepartureTime: "",
            latestDepartureTime: "",
            earliestDepartureDateTime: "",
            latestDepartureDateTime: ""
        },
        getArrivalTimeRange: {
            earliestArrivalTime: "",
            latestArrivalTime: "",
            earliestArrivalDateTime: "",
            latestArrivalDateTime: ""
        },
        getTotalDuration: {
            minTotalTime: "",
            maxTotalTime: "",
            minTotalDuration: 0,
            maxTotalDuration: 0
        },
        getLayoverTime: {
            maxLayoverDuration: 0,
            maxLayoverTime: "",
            minLayoverDuration: 0,
            minLayoverTime: ""
        }
    };

    interface FilterData {
        findCarriers: any[];
        noOfStops: any[];
        getBaggageData: any[];
        getDepartureAirport: any[];
        getArrivalAirport: any[];
        getLayovers: any[];


    }

    type FilterDataType = keyof FilterData;
    interface SliderFilterData {
        findMinMaxValues: { minimumValue: 0, maximumValue: 0 }
        getDepartureTimeRange: { earliestDepartureTime: "", latestDepartureTime: "", earliestDepartureDateTime: "", latestDepartureDateTime: "" };
        getArrivalTimeRange: { earliestArrivalTime: "", latestArrivalTime: "", earliestArrivalDateTime: "", latestArrivalDateTime: "" }
        getTotalDuration: { minTotalTime: "", maxTotalTime: "", minTotalDuration: 0, maxTotalDuration: 0 }
        getLayoverTime: { maxLayoverDuration: 0, maxLayoverTime: "", minLayoverDuration: 0, minLayoverTime: "" }
    }

    type SliderFilterDataType = keyof SliderFilterData;


    useEffect(() => {
        let newdata: any = {};

        const filters = ["findCarriers", "noOfStops", "getBaggageData", "getDepartureAirport", "getArrivalAirport", "getLayovers"]
        const constantFilters = ["findMinMaxValues", "getDepartureTimeRange", "getArrivalTimeRange", "getTotalDuration", "getLayoverTime"]
        filters.forEach(elem => {
            let obj = filter[elem];
            newdata[elem] = [];

            for (const key in obj) {
                let element = { ...obj[key] };
                newdata[elem].push({ [key]: element })
            }
        })
        constantFilters.forEach((elem) => {
            let obj = filter[elem];
            newdata[elem] = obj;
        })
        setFilterData(newdata);

    }, [filter])

    useEffect(() => {
        if (props?.resetFilter) {
            clearAll()
            props?.resetFilters(false)
        }

    }, [props?.resetFilter])

    useEffect(() => {
        if (filterData?.getArrivalAirport) {
            let val = filterData?.getArrivalAirport?.[0]?.[0]?.arrivalAirportCity
            setToCity(val)
        }
        if (filterData?.getDepartureAirport) {
            let val = filterData?.getDepartureAirport?.[0]?.[0]?.departureAirportCity
            setFromCity(val)
        }
    }, [filterData])

    const filterChange = (e: ChangeEvent<HTMLInputElement>, value: any) => {
        let appliedFilterData = { ...appliedFilter };

        const name: string = e.target.name;
        let currentValue = e.target.checked;


        if (name in filterData) {

            let type: FilterDataType = name as FilterDataType;
            const index = appliedFilterData[type].indexOf(value);


            if (index > -1) {
                appliedFilterData[type].splice(index, 1);
            } else {
                appliedFilterData[type].push(value);
            }


            setAppliedFilter(appliedFilterData);
            setAllChecked(false);
            props?.handleAppliedFilterSateChange(appliedFilterData)
        } else {
            console.error(`Invalid filter type: ${name}`);
        }

    }

    const onChangeFunctionData = (e: React.ChangeEvent<HTMLInputElement>, filterValue: any) => {
        filterChange(e, filterValue)
    }

    const sliderFilterChange = (e: React.ChangeEvent<HTMLInputElement>, value: any) => {

        let appliedFilterData = { ...appliedFilter };
        const name: string = e.target.name;

        if (name in filterData) {
            let type: SliderFilterDataType = name as SliderFilterDataType;
            if (
                appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "minimumValue" in appliedFilterData[type] &&
                "maximumValue" in appliedFilterData[type]
            ) {
                const sliderFilterData: { minimumValue: number; maximumValue: number } = appliedFilterData[type] as any;
                sliderFilterData.minimumValue = value[0];
                sliderFilterData.maximumValue = value[1];
            } else if (appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "earliestDepartureTime" in appliedFilterData[type] &&
                "latestDepartureTime" in appliedFilterData[type]) {

                const sliderFilterData: { earliestDepartureTime: "", latestDepartureTime: "", earliestDepartureDateTime: Date, latestDepartureDateTime: Date } = appliedFilterData[type] as any;
                sliderFilterData.earliestDepartureTime = value[0];
                sliderFilterData.earliestDepartureDateTime = new Date(value[0]);
                sliderFilterData.latestDepartureTime = value[1];
                sliderFilterData.latestDepartureDateTime = new Date(value[1]);
            } else if (appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "earliestArrivalTime" in appliedFilterData[type] &&
                "latestArrivalTime" in appliedFilterData[type]) {


                const sliderFilterData: { earliestArrivalTime: "", latestArrivalTime: "", earliestArrivalDateTime: Date, latestArrivalDateTime: Date } = appliedFilterData[type] as any;
                sliderFilterData.earliestArrivalTime = value[0];
                sliderFilterData.earliestArrivalDateTime = new Date(value[0]);
                sliderFilterData.latestArrivalTime = value[1];
                sliderFilterData.latestArrivalDateTime = new Date(value[1]);
            } else if (appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "minTotalTime" in appliedFilterData[type] &&
                "maxTotalTime" in appliedFilterData[type]) {
                const sliderFilterData: { minTotalTime: "", maxTotalTime: "", minTotalDuration: 0, maxTotalDuration: 0 } = appliedFilterData[type] as any;
                sliderFilterData.minTotalTime = timeConvert(value[0]);
                sliderFilterData.minTotalDuration = value[0];
                sliderFilterData.maxTotalTime = timeConvert(value[1]);
                sliderFilterData.maxTotalDuration = value[1];
            } else if (appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "maxLayoverDuration" in appliedFilterData[type] &&
                "minLayoverDuration" in appliedFilterData[type]) {
                const sliderFilterData: { maxLayoverDuration: 0, maxLayoverTime: "", minLayoverDuration: 0, minLayoverTime: "" } = appliedFilterData[type] as any;
                sliderFilterData.minLayoverTime = timeConvert(value[0]);
                sliderFilterData.minLayoverDuration = value[0];
                sliderFilterData.maxLayoverTime = timeConvert(value[1]);
                sliderFilterData.maxLayoverDuration = value[1];
            } else {
                // Handle the case where the property does not exist or is not an object
            }
            setAppliedFilter(appliedFilterData);
            setAllChecked(false);
            props?.handleAppliedFilterSateChange(appliedFilterData);
        } else {
            console.error(`Invalid filter type: ${name}`);
        }


    }

    const handleSelectOnlyData = (name: any, value: any) => {


        let appliedFilterData = { ...appliedFilter };

        if (name in filterData) {

            let type: FilterDataType = name as FilterDataType;
            appliedFilterData[type].length = 0;
            appliedFilterData[type].push(value);

            setAppliedFilter(appliedFilterData);
            setAllChecked(false)

            props?.handleAppliedFilterSateChange(appliedFilterData)
        } else {
            console.error(`Invalid filter type: ${name}`);
        }

    }

    const clearAll = () => {

        let newValue = { ...defaultFilter };
        setAppliedFilter(newValue)
        props?.handleAppliedFilterSateChange(newValue)
        setPriceRangeReset(true)
        setDateReset(true)
        setTimeReset(true)
        setAllChecked(false)
    }

    const onClear = (name: any) => {
        let appliedFilterData = { ...appliedFilter };
        let newValue = { ...defaultFilter };

        let type: SliderFilterDataType = name as SliderFilterDataType;

        let newFilterValue = newValue[type];


        if (name in filterData) {
            let type: SliderFilterDataType = name as SliderFilterDataType;
            if (
                appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "minimumValue" in appliedFilterData[type] &&
                "maximumValue" in appliedFilterData[type]
            ) {
                const sliderFilterData: { minimumValue: number; maximumValue: number } = appliedFilterData[type] as any;
                sliderFilterData.minimumValue = 0;
                sliderFilterData.maximumValue = 0;
                setPriceRangeReset(true)
            } else if (appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "earliestDepartureTime" in appliedFilterData[type] &&
                "latestDepartureTime" in appliedFilterData[type]) {

                const sliderFilterData: { earliestDepartureTime: "", latestDepartureTime: "", earliestDepartureDateTime: "", latestDepartureDateTime: "" } = appliedFilterData[type] as any;
                sliderFilterData.earliestDepartureTime = "";
                sliderFilterData.earliestDepartureDateTime = "";
                sliderFilterData.latestDepartureTime = "";
                sliderFilterData.latestDepartureDateTime = "";
                setDateReset(true)
            } else if (appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "earliestArrivalTime" in appliedFilterData[type] &&
                "latestArrivalTime" in appliedFilterData[type]) {


                const sliderFilterData: { earliestArrivalTime: "", latestArrivalTime: "", earliestArrivalDateTime: "", latestArrivalDateTime: "" } = appliedFilterData[type] as any;
                sliderFilterData.earliestArrivalTime = "";
                sliderFilterData.earliestArrivalDateTime = "";
                sliderFilterData.latestArrivalTime = "";
                sliderFilterData.latestArrivalDateTime = "";
                setDateReset(true)
            } else if (appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "minTotalTime" in appliedFilterData[type] &&
                "maxTotalTime" in appliedFilterData[type]) {
                const sliderFilterData: { minTotalTime: "", maxTotalTime: "", minTotalDuration: 0, maxTotalDuration: 0 } = appliedFilterData[type] as any;
                sliderFilterData.minTotalTime = "";
                sliderFilterData.minTotalDuration = 0;
                sliderFilterData.maxTotalTime = "";
                sliderFilterData.maxTotalDuration = 0;
                setTimeReset(true)
            } else if (appliedFilterData[type] &&
                typeof appliedFilterData[type] === "object" &&
                "maxLayoverDuration" in appliedFilterData[type] &&
                "minLayoverDuration" in appliedFilterData[type]) {
                const sliderFilterData: { maxLayoverDuration: 0, maxLayoverTime: "", minLayoverDuration: 0, minLayoverTime: "" } = appliedFilterData[type] as any;
                sliderFilterData.minLayoverTime = "";
                sliderFilterData.minLayoverDuration = 0;
                sliderFilterData.maxLayoverTime = "";
                sliderFilterData.maxLayoverDuration = 0;
                setTimeReset(true)
            }
            setAppliedFilter(appliedFilterData);
            props?.handleAppliedFilterSateChange(appliedFilterData);

        }


    }

    const resetFlag = (name: any, flag: Boolean) => {
        if (name == "findMinMaxValues") {
            setPriceRangeReset(false)
        } else if (name == "getDepartureTimeRange" || name == "getArrivalTimeRange") {
            setDateReset(false)
        } else if (name == "getTotalDuration" || name == "getLayoverTime") {
            setTimeReset(false)
        }
    }

    const handleFilterToggleMb = () => {
        setFilterToggleMb(!filterToggleMb);
        const bodyEle = document.querySelectorAll("body"); // set body element class for body scroll hide  
        !filterToggleMb ? bodyEle[0].classList.add('filter-active') : bodyEle[0].classList.remove('filter-active');
    }

    const baggageTypeToTitleCase = (baggage: string) => {
        return baggage
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (<>
        {!matches &&
            <Box className="serachRes_FltShowHide">
                <TuneIcon className='searchRes_FilterIcon' onClick={handleFilterToggleMb} />
            </Box>}
        <Box className={"searchRes_FilterMB " + (filterToggleMb && " searchRes_FilterMBShow")}>
            {!matches &&
                <Box className="searchRes_FilterMB_top">
                    <Box className="searchRes_titleBox">
                        <Typography className='searchRes_Title'><TuneIcon className='searchRes_FilterIcon' /> {t('Filter & Sort by')}</Typography>
                        <CloseIcon onClick={handleFilterToggleMb} />
                    </Box>
                    <Box>
                    </Box>
                </Box>
            }

            <Box className='serachRes_filterContent'>
                <Box className="searchRes_clearSection">

                    {matches ? <Typography className='serachRes_FilterBtn'><TuneIcon className='searchRes_FilterIcon' /> {t('Filter')}</Typography> :
                        <Button className='searchRes_RecomBtn'>{t('Recommended')} <ImportExportIcon /></Button>}
                    <Button className='searchReu_clearBtn' onClick={() => clearAll()}>{t('Clear All')}</Button>
                </Box>

        {filterData && filterData.hasOwnProperty("noOfStops") && filterData.noOfStops.length > 0 &&


            <Box className="searchRes_filterSection">
                <FilterTopTitle mainTitle="Stops" subTitle={t("From")} />
                <FilterTitle Title={`${fromCity} ${t('to')} ${toCity}`} />

                {filterData?.noOfStops?.map((item: any) => {
                    
                    let stops = Object.keys(item)[0];
                    let stopData: any = Object.values(item)[0];
                    const minFare = stopData?.fare && parseAmount(stopData.fare);
                    const stopLength = stopData.count;
                    let displayValue = stops + ' (' + stopLength + ')'
                    let checkValue = (appliedFilter?.noOfStops.includes(stops) || allChecked)
                    return (
                        <div>
                            <FilterCheckBox
                                CheckBoxlable={displayValue}
                                CheckBoxValue={minFare}
                                name={"noOfStops"}
                                TripInfoData={TripInfoData || {}}
                                onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFunctionData(e, stops)}
                                checkValue={checkValue}
                                handleSelectOnly={() => handleSelectOnlyData("noOfStops", stops)}
                                showDollarSign={true}
                            />
                        </div>
                    )


                })}
            </Box>}
        {filterData && filterData.hasOwnProperty("findCarriers") && filterData.findCarriers.length > 0 &&

            <Box className="searchRes_filterSection">
                <FilterTopTitle mainTitle={t("Airlines")} subTitle={t("From")} />

                {filterData?.findCarriers?.map((item: [CarrierFilter]) => {

                    let [carrierData] = Object.values(item);
                    let { carrierName = "", carrier = "", fare = 0, count = 0 } = carrierData || {};
                    let displayValue = `${carrierName} ${carrier} (${count})`;
                    let checkValue = (appliedFilter?.findCarriers.includes(carrier) || allChecked)
                    return (
                        <FilterCheckBox
                            CheckBoxlable={displayValue}
                            CheckBoxValue= {parseAmount(fare)}
                            TripInfoData={TripInfoData || {}}
                            name={"findCarriers"}
                            onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFunctionData(e, carrier)}
                            checkValue={checkValue}
                            handleSelectOnly={() => handleSelectOnlyData("findCarriers", carrier)}
                            showDollarSign={true}
                        />
                    )
                })}

            </Box>
        }
        {filterData && filterData.hasOwnProperty("getBaggageData") && filterData.getBaggageData.length > 0 &&
            <Box className="searchRes_filterSection">
                <FilterTopTitle mainTitle={t('Baggage Allowance')} subTitle={t('From')} />

                {filterData?.getBaggageData?.map((item: any, index: number) => {
                    let baggageType = Object.keys(item)[0];
                    // Asserting item to have a specific type to avoid TypeScript error
                    let baggageData: { count?: number, fare?: number } = Object.values(item)[0] as { count?: number, fare?: number };
                    let baggageLength = baggageData.count;
                    let fare = baggageData.fare ?? 0;
                    let displayValue = baggageType + ' (' + baggageLength + ')'
                    let checkValue = (appliedFilter?.getBaggageData.includes(baggageType) || allChecked)
                    const displayValueBaggage = baggageTypeToTitleCase(displayValue)

                    return (
                        <div>
                            <FilterCheckBox
                                key={index}
                                CheckBoxlable={displayValueBaggage}
                                CheckBoxValue={parseAmount(fare)}
                                TripInfoData={TripInfoData || {}}
                                icon={LuggageIcon}
                                name={"getBaggageData"}
                                onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFunctionData(e, baggageType)}
                                checkValue={checkValue}
                                handleSelectOnly={() => handleSelectOnlyData("getBaggageData", baggageType)}
                                showDollarSign={true}
                            />

                        </div>
                    )
                })
                }

            </Box>}

        {filterData && filterData.hasOwnProperty("getDepartureAirport") && filterData.getDepartureAirport.length > 0 &&


            <Box className="searchRes_filterSection">
                <FilterTopTitle mainTitle={t("Airports")} subTitle={t("From")} />
                {filterData.getDepartureAirport.length > 0 && <FilterTitle Title={`${fromCity} ${t('to')} ${toCity}`} />}
                {filterData?.getDepartureAirport?.map((item: any, i: number) => {


                    let departureAirport = item?.[i]?.departureAirport
                    let departureAirportCity = item?.[i]?.departureAirportCity
                    let minFare = item?.[i]?.minFare
                    let displayValue = departureAirport + ' (' + departureAirportCity + ')'
                    let checkValue = (appliedFilter?.getDepartureAirport.includes(departureAirport) || allChecked)

                    return (
                        <div>

                            <FilterCheckBox
                                CheckBoxlable={displayValue}
                                CheckBoxValue={minFare}
                                TripInfoData={TripInfoData || {}}
                                name={"getDepartureAirport"}
                                onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFunctionData(e, departureAirport)}
                                checkValue={checkValue}
                                handleSelectOnly={() => handleSelectOnlyData("getDepartureAirport", departureAirport)}
                                showDollarSign={false}
                            />

                        </div>
                    )


                })}

                {filterData.getArrivalAirport.length > 0 && <FilterTitle Title={`${toCity} ${t('to')} ${fromCity}`} />}
                {filterData?.getArrivalAirport?.map((item: any, i: number) => {


                    let arrivalAirport = item?.[i]?.arrivalAirport
                    let arrivalAirportCity = item?.[i]?.arrivalAirportCity
                    let minFare = item?.[i]?.minFare
                    let displayValue = arrivalAirport + ' (' + arrivalAirportCity + ')'
                    let checkValue = (appliedFilter?.getArrivalAirport.includes(arrivalAirport) || allChecked)

                    return (
                        <div>

                            <FilterCheckBox
                                CheckBoxlable={displayValue}
                                CheckBoxValue={minFare}
                                TripInfoData={TripInfoData || {}}
                                name={"getArrivalAirport"}
                                onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFunctionData(e, arrivalAirport)}
                                checkValue={checkValue}
                                handleSelectOnly={() => handleSelectOnlyData("getArrivalAirport", arrivalAirport)}
                                showDollarSign={false}
                            />

                        </div>
                    )


                })}
            </Box>}

        {filterData && filterData.hasOwnProperty("findMinMaxValues") && filterData?.findMinMaxValues &&

            <Box className="searchRes_filterSection">
                <FilterTopTitle mainTitle={t('Price Range')} subTitle={t('Clear')} onClear={() => onClear("findMinMaxValues")} />
                <FilterRangeTilte ToRange={filter?.findMinMaxValues?.minimumValue} FromRange={filter?.findMinMaxValues?.maximumValue} />
                <FilterRange 
                    sliderMinValue={filterData?.findMinMaxValues?.minimumValue}
                    sliderMaxValue={filterData?.findMinMaxValues?.maximumValue}
                    steps={((filter?.findMinMaxValues?.maximumValue - filter?.findMinMaxValues?.minimumValue) / 10)} 
                    name={"findMinMaxValues"} 
                    sliderFilterChange={(e: React.ChangeEvent<HTMLInputElement>, value: any) => sliderFilterChange(e, value)} displayValue={appliedFilter?.findMinMaxValues} 
                    resetValue={priceRangeReset} 
                    resetFlag={resetFlag} />
            </Box>}


        {filterData && filterData.hasOwnProperty("getDepartureTimeRange") && filterData?.getDepartureTimeRange &&
            <Box className="searchRes_filterSection">
                <FilterTopTitle mainTitle={t('Timings')} subTitle={t('Clear')} onClear={() => onClear("getDepartureTimeRange")} />
                <FilterTitle Title={`${t('Take-off from')} ${fromCity}`} />
                <FilterRangeTilte ToRange={filterData?.getDepartureTimeRange?.earliestDepartureTime} FromRange={filterData?.getDepartureTimeRange?.latestDepartureTime} />
                <FilterRange sliderMinValue={filterData?.getDepartureTimeRange?.earliestDepartureDateTime} sliderMaxValue={filterData?.getDepartureTimeRange?.latestDepartureDateTime} name={"getDepartureTimeRange"} sliderFilterChange={(e: React.ChangeEvent<HTMLInputElement>, value: any) => sliderFilterChange(e, value)} steps={3600000} displayValue={appliedFilter?.getDepartureTimeRange} resetValue={dateReset} resetFlag={resetFlag} />
                <FilterTitle Title={`${t('Landing at')} ${toCity}`} />
                <FilterRangeTilte ToRange={filterData?.getArrivalTimeRange?.earliestArrivalTime} FromRange={filterData?.getArrivalTimeRange?.latestArrivalTime} />
                <FilterRange sliderMinValue={filterData?.getArrivalTimeRange?.earliestArrivalDateTime} sliderMaxValue={filterData?.getArrivalTimeRange?.latestArrivalDateTime} name={"getArrivalTimeRange"} sliderFilterChange={(e: React.ChangeEvent<HTMLInputElement>, value: any) => sliderFilterChange(e, value)} steps={3600000} displayValue={appliedFilter?.getArrivalTimeRange} resetValue={dateReset} resetFlag={resetFlag} />
            </Box>}

        {filterData && filterData.hasOwnProperty("getTotalDuration") && filterData?.getTotalDuration &&
            <Box className="searchRes_filterSection searchRes_TravelTime">
                <FilterTopTitle mainTitle={t('Travel Time')} subTitle={t('Clear')} onClear={() => onClear("getLayoverTime")} />
                <FilterTitle Title={t('Flight Duration')} />
                <FilterRangeTilte ToRange={filterData?.getTotalDuration?.minTotalTime} FromRange={filterData?.getTotalDuration?.maxTotalTime} />
                <FilterRange sliderMinValue={filterData?.getTotalDuration?.minTotalDuration} sliderMaxValue={filterData?.getTotalDuration?.maxTotalDuration} name={"getTotalDuration"} sliderFilterChange={(e: React.ChangeEvent<HTMLInputElement>, value: any) => sliderFilterChange(e, value)} steps={60} displayValue={appliedFilter?.getTotalDuration} resetValue={timeReset} resetFlag={resetFlag} />

                <FilterTitle Title={t('Layover')} />
                <FilterRangeTilte ToRange={filterData?.getLayoverTime?.minLayoverTime} FromRange={filterData?.getLayoverTime?.maxLayoverTime} />
                <FilterRange sliderMinValue={filterData?.getLayoverTime?.minLayoverDuration} sliderMaxValue={filterData?.getLayoverTime?.maxLayoverDuration} name={"getLayoverTime"} sliderFilterChange={(e: React.ChangeEvent<HTMLInputElement>, value: any) => sliderFilterChange(e, value)} steps={60} displayValue={appliedFilter?.getLayoverTime} resetValue={timeReset} resetFlag={resetFlag} />

            </Box>
        }

        {filterData && filterData.hasOwnProperty("getLayovers") && filterData.getLayovers.length > 0 &&


            <Box className="searchRes_filterSection">
                <FilterTopTitle mainTitle={t('Layover Airports')} />

                {filterData?.getLayovers?.map((item: LayoverInterface, i: any) => {

                    let [layoverData] = Object.values(item);

                    let airport = layoverData?.airport
                    let airportCity = layoverData?.airportCity

                    let displayValue = airportCity + ' (' + airport + ')'
                    let checkValue = (appliedFilter?.getLayovers.includes(airport) || allChecked)

                    return (
                        <div>

                            <FilterCheckBox
                                CheckBoxlable={displayValue}
                                TripInfoData={TripInfoData || {}}
                                name={"getLayovers"}
                                onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFunctionData(e, airport)}
                                checkValue={checkValue}
                                handleSelectOnly={() => handleSelectOnlyData("getLayovers", airport)}
                                showDollarSign={false}
                            />

                        </div>
                    )


                })}

                    </Box>}

            </Box>
            {!matches ?
                <Box className="searchRes_MbBtn">
                    <Button className='searchRes_BtnClearAll' variant="outlined" onClick={() => clearAll()}>{t('Clear all')}</Button>
                    <Button className='searchRes_BtnApply' variant="contained" onClick={handleFilterToggleMb} >{t('Apply')}</Button>
                </Box> :<>
                {/* <Box className="searchRes_FeedBack">
                    <Typography className='serachRes_feedTitle'>Help us improve your booking experience</Typography>
                    <Button className='searchRes_FeedBackBtn'>Give feedback</Button>
                </Box> */}
                </>
            }
        </Box>
    </>)
}

export default FilterSection;