import React from 'react';
import { Grid, Box, Typography, TextField, Container, RadioGroup, Radio, FormControlLabel, Button, Checkbox, FormControl, InputLabel, NativeSelect, Select, MenuItem } from '@mui/material';

const SearchCardLoader = () => {
    return (
        <>
            <div className="bg-card">
                <div className="center-space">
                    <div className="placeholder small"></div>
                    <div className="placeholder small"></div>
                    <div className="placeholder small"></div>
                </div>
                <div className="center-space">
                    <div className="placeholder small"></div>
                    <div className="placeholder small"></div>
                    <div className="placeholder small"></div>
                </div>
                <div className="placeholder small"></div>
                <div className="placeholder full"></div>
            </div>
            <div className="bg-card">
                <div className="center-space">
                    <div className="placeholder small"></div>
                    <div className="placeholder small"></div>
                    <div className="placeholder small"></div>
                </div>
                <div className="center-space">
                    <div className="placeholder small"></div>
                    <div className="placeholder small"></div>
                    <div className="placeholder small"></div>
                </div>
                <div className="placeholder small"></div>
                <div className="placeholder full"></div>
            </div>
        </>
    );
};

export default SearchCardLoader;