import React, { ElementType, useEffect, useState } from 'react';
import { Alert, Grid, Popover, TextField, Typography } from '@mui/material';
import { DateRange } from 'react-date-range';
import InputAdornment from '@mui/material/InputAdornment';
import { CHANGE_DATE_TO_LOCALE, DEFAULT_FUNCTION, FROMTO } from '../../utils/constant';
import { GridInterface } from '../../interfaces/commonInterface';
import { enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface DateRangeComponentInterface {
    allowDateRangeSelection?: boolean;
    onChangeMethod: Function;
    gridOptions?: GridInterface & { component?: ElementType<any> };
    name: string;
    label?: string;
    labelCalssName?: string;
    Icon?: ElementType<any>;
    iconPosition?: 'start' | 'end';
    iconClass?: string;
    dateRangeTextFieldClass?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    showNumberOfMonths?: number;
    errorMessage: string;
    minvalue?: Date | null;
}

const id = 'popover-id';

function DateRangepicker({
    allowDateRangeSelection = false,
    onChangeMethod = DEFAULT_FUNCTION,
    gridOptions = {},
    name = "dateRange",
    label = "",
    labelCalssName = "",
    Icon = null,
    iconPosition = "start",
    dateRangeTextFieldClass = "",
    iconClass = "",
    startDate,
    endDate,
    showNumberOfMonths = 2,
    errorMessage = "",
    minvalue
}: DateRangeComponentInterface) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [selection, setSelection]: any = useState({
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    });

    useEffect(() => {
        setSelection({
            startDate: null,
            endDate: null,
            key: 'selection',
        })
    }, [errorMessage]);

    useEffect(() => {
        setSelection({
            startDate: startDate,
            endDate: (!allowDateRangeSelection ? startDate : endDate),
            key: 'selection',
        });
    }, [startDate, endDate]);

    const [removeClass, setRemoveClass] = useState(true);

    const twoDaysLaterDate = (date: any) => {
        const afterDate = new Date(date.getTime() + 48 * 60 * 60 * 1000);
        return afterDate;
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);

        if (removeClass) {
            setTimeout(function () {
                let elements = document.querySelectorAll('span.rdrInRange');
                elements.forEach(element => {
                    element.classList.remove('rdrInRange');
                });
            }, 80);
        }
    };
    // useEffect(() => {
    //     const targetDiv = document.querySelector('#B2CHeader') as HTMLElement | null;
      
    //     if (!targetDiv) {
    //     //   console.warn("Waiting for #B2CHeader to render...");
    //       return;
    //     }
      
    //     if (open) {
    //       targetDiv.style.paddingRight = "17px";
    //     } else {
    //       targetDiv.style.paddingRight = "0px";
    //     }
    //   }, [open]);
      

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (ranges: any) => {
        const { startDate, endDate } = ranges?.selection;
        setRemoveClass(false);

        if (!allowDateRangeSelection) {
            setSelection({
                startDate: startDate,
                endDate: startDate,
                key: 'selection',
            });
            onChangeMethod(startDate, FROMTO.FROM);
            setOpen(false);
            setRemoveClass(true);
        } else {
            if (startDate && endDate) {
                setSelection(ranges.selection);
                onChangeMethod(startDate, FROMTO.FROM);
                onChangeMethod(endDate, FROMTO.TO);
                if (startDate !== endDate) {
                    setOpen(false);
                }
            } else {
                setSelection({ startDate: null, endDate: null, key: 'selection' });
            }
        }
    };

    const formattedRange = allowDateRangeSelection
        ? `${selection.startDate ? moment(selection.startDate).format('MM/DD/YYYY') : ''} - ${selection.endDate ? moment(selection.endDate).format('MM/DD/YYYY') : ''}`
        : selection.startDate ? moment(selection.startDate).format('MM/DD/YYYY') : '';
  
    const minDate = (allowDateRangeSelection && selection.startDate && selection.endDate)
        ? twoDaysLaterDate(new Date())
        : (minvalue) ? minvalue : twoDaysLaterDate(new Date());


    return (
        <Grid {...gridOptions}>
            <Typography className={labelCalssName}>{label}</Typography>
            <TextField
                aria-describedby={id}
                value={formattedRange || ''}
                onClick={handleClick}
                placeholder={t('Enter date')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={iconPosition}>
                            {Icon && <Icon className={iconClass} />}
                        </InputAdornment>
                    ),
                }}
                className={dateRangeTextFieldClass}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <DateRange
                    ranges={[selection]}
                    onChange={handleSelect}
                    months={showNumberOfMonths}
                    direction="horizontal"
                    showDateDisplay={true}
                    locale={enUS}
                    minDate={minDate}
                    className={formattedRange == '' || formattedRange == " - " ? 'rangeValueNotSelected' : ''}
                />
            </Popover>
            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
        </Grid>
    );
}

export default DateRangepicker;
