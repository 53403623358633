import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import { getSegmentData } from "../../../utils/commonFunctions";
import { useTranslation } from 'react-i18next';

interface TermsOfServiceInterface {
    segmentData: Segment[];
    outboundFareRules: OutBoundInBoundFares[];
    inboundFareRules: OutBoundInBoundFares[];

}

interface OutBoundInBoundFares {
    fareBasisCode: string;
    FareRuleDetails?: FareRules[]
}
interface FlightData {
    flights: any;  // Define the proper type for flights
}

interface Segment {
    [key: string]: FlightData;
}

interface FareRules {
    title: String,
    text: String
}
//   interface FlightData {
//     departureCity: string;
//     arrivalCity: string;
//     // other properties
// }



const TermsOfService: React.FC<TermsOfServiceInterface> = ({ segmentData, outboundFareRules, inboundFareRules }) => {
    const { t } = useTranslation();
    // let segmentData: Segment[] | undefined =props.segmentData;
    const [segmentsData, setSegmentData] = React.useState([]);
    // let outboundFareRules = props?.outboundFareRules
    // let inboundFareRules = props?.inboundFareRules


    React.useEffect(() => {
        const segments = segmentData

        if (segments) {
            const segmentsData = segments.flatMap(segment =>
                getSegmentData(Object.values(segment)[0])
            );

            // const travelData = segmentsData.map(segment => ({
            //     departureCity: segment.fromAirportCode,
            //     departureAirport: segment.fromCity,
            //     arrivalCity: segment.toAirportCode,
            //     arrivalAirport: segment.toCity
            // }));
            setSegmentData(segmentsData);
        }
    }, [segmentData])

    return (
        <Box className="conftion__wrapper termsOfBooking">
            <Box className="conftion__header">

                <Typography className='conftionTitle'>{t('Terms Of Booking')}</Typography>

            </Box>
            <hr className='conftion_hr' />

            <Box className="conftion__infoTag">
                <Box className="conftion_infoheader">
                    <InfoIcon />
                    <Typography className='conftion_infoTitle'>
                        {t('Your itinerary consists of two one-way fares which are subjects to their own rules and restrictions. If one of your flights is changed or canceled, it will not automatically change the other flight. You may incur a penalty free for each flight for additional itinerary changes.')}
                    </Typography>
                </Box>
            </Box>

            <Box>
                <Box className="termsOfBooking_list">
                    
                    {segmentsData?.length ? segmentsData.map((segment, index) => {
                        let rules = index === 0 ? outboundFareRules : inboundFareRules;
                        console.log("rules",rules)
                        return (
                        <Box className="termsOfBooking_left">
                        {segment.departureCity && segment.arrivalCity && (
                            <>
                                <Typography className='termsOfBooking_tilte'>
                                    {segment.departureCity} {t('to')} {segment.arrivalCity}
                                </Typography>
                            </>
                        )}
                        {
                           
                           rules?.map((item: any, index: number) => {
                            return (
                                <Accordion key={index} className='termsOfBooking_ul'>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                    >
                                        {/* Display fareBasisCode */}
                                        <Typography>{item?.fareBasisCode || "No Fare Basis Code"}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {item?.FareRuleDetails && item.FareRuleDetails.length > 0 ? (
                                                item.FareRuleDetails.map((rule: any, ruleIndex: number) => (
                                                    <Accordion key={ruleIndex} className="subAccordion">
                                                        <AccordionSummary
                                                            expandIcon={<ArrowDropDownIcon />}
                                                            aria-controls={`subPanel${ruleIndex}-content`}
                                                            id={`subPanel${ruleIndex}-header`}
                                                        >
                                                            {/* Display rule title */}
                                                            <Typography>{rule?.title || "No Title"}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {/* Display rule text */}
                                                            <div className="title-case">{rule?.text || "No Rule Text"}</div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))
                                            ) : (
                                                <div>No Fare Rule Details Available</div>
                                            )}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })
                        }
                    </Box>
                    )}) : null}

                </Box>

                <Typography className='Terms_FooterTilte'>
                    {t('Taxes and fees are included in the total ticket costs. Prices may not include (baggage fee) or other fees charged directly by the airline. Fares are not guaranteed until ticketed. Service fees are non-refundable. Cancellations, routing and date changes are subject to (fare rules) and our (fees), should these changes be allowed by the airline.')}
                </Typography>
            </Box>
        </Box>
    )
}

export default TermsOfService;