import React, { useState } from 'react';
import { getProviderConfigService } from "../services";

const GetProviderData = () => {
    const [providerConfig, setProviderConfig] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const getProviderConfig = async () => {

        try {
            setLoading(true);
            setError(null);
            const res = await getProviderConfigService();
            
            // let data={
            //     softvoyage:{
            //         skipBrandedFares:true
            //     },
            //     default:{
            //         skipBrandedFares:false
            //     }
            // }
            setProviderConfig(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [getProviderConfig, providerConfig, loading, error];
};

export { GetProviderData };