import React,{useEffect, useState} from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CardLabel } from '../CardLabel';
import { TRIPTYPE } from '../../../utils/constant';
import moment from 'moment';
import { formatDate, formatTime } from '../../../utils/util';
import { useTranslation } from 'react-i18next';

interface FlightData {
    flights: any;  // Define the proper type for flights
  }
  
  interface Segment {
    [key: string]: FlightData;
  }
  
  interface Props {
    segmentData?: Segment[];
  }


export default function BookingSummary(props: any) {
    const { t } = useTranslation();
    let [departureCity,setDepartureCity] = React.useState("")
    let [destinationCity,setDestinationCity] = React.useState("")
    let [departureCityCode,setDepartureCityCode] = React.useState("")
    let [destinationCityCode,setDestinationCityCode] = React.useState("")
    let [departureDate,setDepartureDate] = React.useState("")
    let [destinationDate,setDestinationDate] = React.useState("")
    const [segmentData, setSegmentData] = useState([]);

    let segments: Segment[] | undefined = props?.segmentData;
    let tripType=props?.tripType;

    useEffect(()=>{
        if(segments){
            let segLength = segments.length;
            let outboundData = segments && segments[0] && Object.values(segments[0])?.[0] && Object.values(segments[0])?.[0];
            let outboundFlight = outboundData?.flights;
            let outFlightLength = outboundFlight?.length;
            if(outboundFlight && outboundFlight[0]){
                setDepartureDate(outboundFlight?.[0]?.departureDateTime)
                setDepartureCity(outboundFlight?.[0]?.departureAirportCity)
                setDepartureCityCode(outboundFlight?.[0]?.departureAirport)
            }
    
            if(tripType==TRIPTYPE.ROUNDTRIP){
                let inboundData = segments && segments[1] && Object.values(segments[1])?.[0] && Object.values(segments[1])?.[0];
                let inboundFlight = inboundData?.flights;
                let inFlightLength = inboundFlight.length;
    
                if(inboundFlight && inboundFlight[inFlightLength-1]){
                    setDestinationCity(inboundFlight?.[0]?.departureAirportCity)
                    setDestinationCityCode(inboundFlight?.[0]?.departureAirport)
                    setDestinationDate(inboundFlight[inFlightLength-1]?.arrivalDateTime)
                }
    
    
            }else if(tripType==TRIPTYPE.ONEWAY){
                if(outboundFlight && outboundFlight?.[outFlightLength-1]){
                    setDestinationCity(outboundFlight?.[outFlightLength-1]?.arrivalAirportCity)
                    setDestinationCityCode(outboundFlight?.[outFlightLength-1]?.arrivalAirport)
                    setDestinationDate("")
                }
    
            }else if(tripType ==  TRIPTYPE.MULTICITY){
                let inboundData = segments && segments[segLength-1] && Object.values(segments[segLength-1])?.[0] && Object.values(segments[segLength-1])?.[0];
                let inboundFlight = inboundData?.flights;
                let inFlightLength = inboundFlight.length;

                setDestinationCity(inboundFlight?.[inFlightLength - 1]?.arrivalAirportCity)
                setDestinationCityCode(inboundFlight?.[inFlightLength - 1]?.arrivalAirport)
                setDestinationDate(inboundFlight?.[inFlightLength - 1]?.arrivalDateTime)
            }
    
        }
    },[segments])

    return (
        <Box className="conftion__wrapper">
            <Typography className='conftionTitle'>{t('Booking Summary')}</Typography>
            <hr className='conftion_hr' />
            
            <Box>
                {/* <Box className="Booking_SummaryContent summary">
                    <CardLabel style={{width: "100%"}} title={`${departureCity}  (${departureCode}) - ${arrivalCity}  (${arrivalCode})`} subTitle="" />
                    <CardLabel title={`${t('Departure Date')}: ${formatDate(departureDatetime)} ${formatTime(departureDatetime)}`} subTitle="" />
                </Box> */}


                <Box className="Booking_SummaryContent">
                    <CardLabel title={`${t('Departing From')}:`} subTitle={`${departureCity}  (${departureCityCode})`} />
                    <CardLabel title={`${t('Departure date')}:`} subTitle={moment(departureDate).format('ddd. MMM D, YYYY')} />
                </Box>
                <Box className="Booking_SummaryContent">
                    <CardLabel title={`${t('Destination')}:`} subTitle={`${destinationCity}  (${destinationCityCode})`} />
                    {destinationDate != "" &&
                        <CardLabel title={`${t('Return Date')}:`} subTitle={moment(destinationDate).format('ddd. MMM D, YYYY')} />
                    }
                </Box>

            </Box>
        </Box>
    )
}