import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TravellerProps {
    traveller: any;
    selectedSeat: string;
    isHighlighted: boolean;
    index: number;
    onClickTraveller: () => void; 
}

const Traveller = ({ traveller, selectedSeat, isHighlighted, index, onClickTraveller }: TravellerProps) => {
    const { t } = useTranslation();
    return (
        <Box
            onClick={onClickTraveller} 
            sx={{
                backgroundColor: isHighlighted ? '#f0f0f0' : 'transparent', 
                cursor: 'pointer',
                padding: '8px',
                borderRadius: '8px',
                marginBottom: '8px',
                border: isHighlighted ? '2px solid #1976d2' : 'none', 
                transition: 'background-color 0.3s ease, border 0.3s ease'
            }}
        >
            <Typography component={'span'} className='SeatMap_footerSeatTitle'>
                {traveller.firstName || `${t('Passenger')} `} {traveller.lastName || !traveller.firstName && !traveller.lastName && index + 1}
            </Typography>
            <Typography component={'span'} className='SeatMap_footerSeatTitle'>
                &nbsp;({selectedSeat || t('Select Seat')})
            </Typography>
        </Box>
    );
};

export default Traveller;
