import React, {useState, useEffect} from 'react';
import SeatMap from '../SeatMap/SeatMap';
import GroupsIcon from '@mui/icons-material/Groups';
import SelectComponent from '../select'; 
import { Container, Grid, Paper, Typography, Button, Box, Link, FormControlLabel, Checkbox } from '@mui/material';
import InsuranceDetail from '../BookWithPaymentComponents/insuranceDetail';
import {LOCPROVINCES, INSURANCEPLANCODE, CALCTOTAL} from '../../../utils/constant';
import { useTranslation } from 'react-i18next';
// @ts-ignore
// import IconInsurance from '../../../assets/icons/Icon-Insurance.svg';
import InfoIcon from '@mui/icons-material/Info';
import IconInsurance from '@mui/icons-material/GppGood';

interface InsuranceForPaxProps {
    quoteResults: any; // Replace `any` with the correct type for quoteResults
    provinceChangeCallback: (provinceCode: string) => void;
    selectedProvince: string;
    insuranceChangeCallback: (newTotalAmount:any,newBaseAmount:any,newPaxOptionArray:any) => void;
}

interface PlanItem  {
  planCode: string;
  planName: string;
  planURL: string;
  emti?: string;
  tci?: string;
  ti?: string;
  mc?: string;
  dr?: string;
  bld?: string;
  bd?: string;
  nmq?: string;
  fr?: string;
  amtaf?: string;
  afa?: string | null;
  ta?: string | null;
  details: string[];
};

const InsuranceForPax: React.FC<InsuranceForPaxProps> = ({ quoteResults, selectedProvince, ...props}) => {
    const { t } = useTranslation();
    const [prevSelectedProvince, setSelectedProvince] = useState<string>(selectedProvince);
    const [showmoreAllInsurance, setShowmoreAllInsurance] = useState<boolean>(true);
    const [paxOptions, setPaxOptions] = useState<any[]>([]);
    const [passengers, setPassengers] = useState<any[]>([]);

    useEffect(() => {
        if (quoteResults && quoteResults.quote) {
          const pax = quoteResults.quote.passengers.passenger.map((_: any, index: number) => ({
            id: index + 1,
            amount: 0,
            base: 0,
            tax: 0,
            perDay: 0,
            insCode: INSURANCEPLANCODE.DECLINED
          }));
          setPaxOptions(pax);
        }
      }, [quoteResults]);
    
      const handleInsChange = (e: React.ChangeEvent<HTMLInputElement>, paxId: number) => {
        const updatedPaxOptions = [...paxOptions];
        updatedPaxOptions[paxId]['insCode'] = e.target.value;
        const plan = getPaxPlanDetail(paxId, e.target.value);
        updatedPaxOptions[paxId] = {
          ...updatedPaxOptions[paxId],
          amount: plan['planTotal'],
          perDay: plan['planPerDay'],
          base: plan['planPrice'],
          tax: plan['planTaxes'],
          name: plan['planName'],
          planName: plan['planName'],
          childamount: plan['planTotal'],
        };
        setPaxOptions(updatedPaxOptions);
      };

      const calcInsTotal = (optionStr: string) => {
        // Your logic to calculate the total
        if(optionStr == CALCTOTAL.amount)
        {
          return paxOptions.reduce((sum, option) => sum + Number(option.amount), 0);
        }else{
          return paxOptions.reduce((sum, option) => sum + Number(option.base), 0);
        }
      };

      useEffect(() => {
        if (paxOptions.length > 0) {
          const totalSum = calcInsTotal(CALCTOTAL.amount);
          const totalBase = calcInsTotal(CALCTOTAL.base); // Assuming it's the same as totalSum
          props.insuranceChangeCallback(totalSum, totalBase, paxOptions);
        }
      }, [paxOptions]);

      const buildPaxOptions = (insPlanCode: string) => {
        return paxOptions.map((_, index) => {
          const planDetail = getPaxPlanDetail(index, insPlanCode);
          return (
            <Box key={`${insPlanCode}-pax-${index}`}  className="insurancePasWrapper">
              <Box className="styled-radio theme-2">
                <input
                  type="radio"
                  onChange={(e) => handleInsChange(e, index)}
                  checked={paxOptions[index]['insCode'] === insPlanCode}
                  id={`${insPlanCode}-pax-${index}`}
                  name={`passenger${index + 1}`}
                  value={insPlanCode}
                  className='insurancePasRadioBtn'
                />
                <label className="pl-4 py-md-1 py-2" htmlFor={`${insPlanCode}-pax-${index}`}>
                  <span>
                    {passengers.length
                      ? `${passengers[index]['first']} ${passengers[index]['last']}`
                      : `${t('Passenger')} ${index + 1}`}
                  </span>
                </label>
              </Box>
              {insPlanCode !== INSURANCEPLANCODE.DECLINED ? (
                <Box className="pl-4 font-weight-bold insurance-price-per-day">
                  {planDetail['planPerDay']}/{t('per day')}
                </Box>
              ) : null}
            </Box>
          );
        });
      };

    const showInsDetail = (e: React.MouseEvent<HTMLButtonElement>) => {
        const url = e.currentTarget.dataset.url;
        window.open(url, '_blank', 'width=740,height=660');
    };

    const getPaxPlanDetail = (paxId: number, code: string) => {
        const pax = quoteResults.quote.passengers.passenger[paxId] || null;
        if (pax) {
          for (const plan of pax['plans']['plan']) {
            if (plan['planCode'] === code) {
              return plan;
            }
          }
        }
        return { planName: "", planTotal: 0, planPerDay: 0, planPrice: 0, planTaxes: 0 };
      };

    const buildInsOptions = () => {
      // console.log("quoteResults insideset----",quoteResults)
        const allPlans = (showmoreAllInsurance && quoteResults.quote.plans.length > 4)
        ? quoteResults.quote.plans.slice(0, 4)
        : quoteResults.quote.plans;
        const paxNumber = quoteResults.quote.passengers.passenger.length;
        const insOptlist = allPlans.length > 0 ? allPlans.map((item: PlanItem) => {
            let planDetail;
            // Create an array of indices to iterate over using for...of
            const indices = Array.from({ length: paxNumber }, (_, i) => i);

            for (const i of indices) {
              planDetail = getPaxPlanDetail(i, item.planCode);
              if (planDetail['planTotal']) {
                break;
              }
            }
            
            const recommendedIns = ['RGIN', 'GIN', 'RGINAM'];
            return (
            <Box className="Booking_InsuranceDetails">
                                {recommendedIns.includes(item.planCode) ? <Box className=" Booking_InsuranceRecommended">{t('Recommended')}</Box> : ''}
                    <Box key={item.planCode} >
                        <Box className={recommendedIns.includes(item.planCode) ? 'Booking_InsuranceMoreInfo' : 'Booking_InsuranceMoreInfo'}>
                            <Box className="Booking_InsuLeftSide">
                              <Box className="Booking_InsuranceTips">
                                  <Typography className='Booking_InsurancTitle'>{item.planName}</Typography>
                                  <button className="Booking_InsuranceTipsBtn" data-url={planDetail.planURL} onClick={e => showInsDetail(e)}>
                                  {/* <svg className="icon" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                      <use xlinkHref={`#icon-info`} />
                                      </svg> */}
                                      <InfoIcon/>
                                  </button>
                              </Box>
                                
                            <Box className="Booking_InsuranceInfo">
                                <Typography className="insurnace-starting-from-text">{t('Starting from')} </Typography>
                                <Typography className="insurance-price"> {planDetail['planTotal']} </Typography>
                                <Typography className="insurance-per-person-text"> {t('per person')} </Typography>
                            </Box>
                            </Box>
                            <Box>
                                {buildPaxOptions(item.planCode)}
                            </Box>
                        </Box>
                            <Box>
                            <InsuranceDetail url={planDetail.planURL} insCode={item.planCode} item={item} />
                            </Box>
                    </Box>
            </Box>
            );
          }) : null;
        
        insOptlist.push(
            <Box className="Booking_InsuranceDetails">
                <Box key={INSURANCEPLANCODE.DECLINED} >
                <Box className='Booking_InsuranceMoreInfo'>
                    <Box className='Booking_InsuLeftSide'>
                    <Typography className="Booking_InsurancTitle">{t('Decline Insurance')}</Typography>
                    </Box>
                    <Box >
                        {buildPaxOptions(INSURANCEPLANCODE.DECLINED)}
                    </Box>
                </Box>
                </Box>
            </Box>);
        return insOptlist;

    }

    const handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedProvince = event.target.value;
        setSelectedProvince(selectedProvince);
        props.provinceChangeCallback(selectedProvince);
      };

      // console.log("selectedProvince",selectedProvince)
      // console.log("paxOptions n",paxOptions)
    return (
        <>
            <Box className="Booking_TitleBox" mt={'10px'}>
                {/* <img src={IconGear} className='Booking_TitleIcon'/> */} 
                {/* <img src={IconInsurance} className='Booking_TitleIcon' /> */}
                <IconInsurance className='Booking_TitleIcon'/>
                <Typography variant="h6" className='Booking_SecondaryTitle'>
                   {t('Add Insurance')}
                </Typography>
            </Box>
            
            <Box className="Booking_TravellerCard">
                <Grid className="searchResults_travelDeals searchResults_Coverage">
                    <Grid item sm={7} xs={12}>
                        <Typography className="travelDeals_title">{t('Coverage valid for Canadian residents only')}</Typography>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <div className="element-container input-chevron-down">
                            <div>
                                <select 
                                    id={`insurance-province`} 
                                    className={`form-control default-select bg-white`}
                                    value={prevSelectedProvince}
                                    onChange={handleProvinceChange}
                                    >
                                    
                                    {Object.keys(LOCPROVINCES).map((key) => (
                                        <option key={`insurance.province.${key}`} value={key}>{LOCPROVINCES[key as keyof typeof LOCPROVINCES]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Box className="Booking_InsuranceDetWraper">
                    {(quoteResults.quote) ? (
                        buildInsOptions()
                    ): (<Typography variant="body1">{quoteResults.error}</Typography>)
                    }
                </Box>
            </Box>
            
        </>
    )
}

export default InsuranceForPax