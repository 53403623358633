import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import AirlineIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import SeatMap from '../SeatMap/SeatMap';
import { TravellerData } from './PassengerDetails';
import { getFlightsFromSegments } from '../SeatMap/helper';
import { getFlightsWithSelectedSeats } from './helper';
import { useTranslation } from 'react-i18next';
import CustIcon from '@mui/icons-material/SettingsSuggest';

interface Seat {
    flightId: string;
    seatId: string;
    travellerId: string;
}

interface Flight {
    id: string;
    departureAirport: string;
    arrivalAirport: string;
    selectedSeats?: Seat[];
}

interface CustomizeYourBookingProps {
    segments: object[];
    itineraryFare: any;
    updateSelectedSeat: (flightId: string, letter: string, row: number, travellerId: string, isSwapping: boolean) => void;
    selectedSeats: any;
    SeatMapAPI: any;
    travellers: TravellerData[];
    token: string;
}

// Modify getFlightsWithSelectedSeats helper function to return Flight[]
const CustomizeYourBooking = ({
    travellers,
    segments,
    itineraryFare,
    updateSelectedSeat,
    selectedSeats,
    SeatMapAPI,
    token
}: CustomizeYourBookingProps) => {
    const { t } = useTranslation();
    const filteredTravellers = travellers.filter(traveller => traveller.paxType !== "InfantInLap");
    const flights: Flight[] = getFlightsWithSelectedSeats(getFlightsFromSegments(segments), selectedSeats) as Flight[];
    // Helper function to get seat for a traveller on a specific flight
    const getSeatForTraveller = (flight: Flight, travellerId: string) => {
        const seat = flight.selectedSeats?.find(seat => seat.travellerId === travellerId);
        return seat ? seat.seatId : '-';
    };

    // Check if any seat is booked for any traveller on any flight
    const hasBookedSeats = flights.some(flight =>
        flight.selectedSeats && flight.selectedSeats.some(seat => seat.seatId)
    );

    return (
        <>
            <Box className="Booking_TitleBox" mt={2}>
               <CustIcon className='Booking_TitleIcon'/>
                <Typography variant="h6" className='Booking_SecondaryTitle'>
                    {t('Customize Your Booking')}
                </Typography>
            </Box>

            <Box className=" Booking_CustomizeBooking">
                <Box className="Booking_TravelcardHeader">
                    <Box className="Booking_TravelHeaderTitle">
                        <AirlineIcon />  
                        <Typography className='SeatMap_Title'>{t('Choose seats')}</Typography>
                    </Box>
                    <SeatMap
                        travellers={filteredTravellers}
                        segments={segments}
                        itineraryFare={itineraryFare}
                        updateSelectedSeat={updateSelectedSeat}
                        selectedSeats={selectedSeats}
                        SeatMapAPI={SeatMapAPI}
                        token={token}
                    />
                </Box>

                {hasBookedSeats && (
                    <Box  className='ChooseSeats_table'>
                        {/* <Typography variant="h6" gutterBottom>
                            {t('Booked Seats Overview')}
                        </Typography> */}
                        <Box>
                        <TableContainer  >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" >
                                <TableHead className='ChooseSeats_tableHead'>
                                    <TableRow>
                                        <TableCell  className='ChooseSeats_filghtsHeader ChooseSeats_TravellerHeader'>
                                            {t('Traveller')}
                                        </TableCell>
                                        {flights.map((flight: any) => (
                                            <TableCell key={flight.id} className='ChooseSeats_filghtsHeader'>
                                                {`(${flight.departureAirport}) ${t('to')} (${flight.arrivalAirport})`}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredTravellers.map((traveller: TravellerData) => (
                                        <TableRow key={traveller.id}>
                                            <TableCell  className='ChooseSeats_traveller'>
                                                {traveller.firstName }  { traveller.lastName}
                                            </TableCell>
                                            {flights.map((flight: any) => (
                                                <TableCell key={`${flight.id}-${traveller.id}`} className='ChooseSeats_travellerInfo'>
                                                    {getSeatForTraveller(flight, traveller.id)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default CustomizeYourBooking;
