import React from "react";
import { useDispatch } from "react-redux";
import { setLanguageChanged, setProviderUpdate } from "../../redux/actions/language";
import { LanguageSelectorDropDown } from 'gigi-component-library';
import { useSelector } from "react-redux";
import { GetProviderData } from "gigi-api-layer/src/";

export default function LanguageDropDown() {
    const dispatch = useDispatch();
    const appLanguage = useSelector((state: any) => state.languageChange.language);

    const setApplicationLangHandler = (target: any) => {
        dispatch(setLanguageChanged(target))
    }

    const [getProviderConfig, providerConfig, providerConfigLoader, providerConfigError] = GetProviderData();
    React.useEffect(()=>{
        let res=getProviderConfig()
    },[])

    React.useEffect(()=>{
        if(providerConfig){
            dispatch(setProviderUpdate(providerConfig))
        }

    },[providerConfig])

    return (<>
        <LanguageSelectorDropDown handleLangChange={setApplicationLangHandler} language={appLanguage} />
    </>);
}